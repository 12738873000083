<template>
  <b-sidebar
    id="export-sidebar"
    :visible="isExportSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-export-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Export To EXCEL | XLSX
          <!-- {{ apiGenereateExcel }} -->
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Group Account -->
          <b-form-group
            v-if="myData.ignoreGroup === false && $can('manage', 'all')"
            label="Group Account"
            label-for="group_account"
          >
            <vue-autosuggest
              v-model="myData.groupAccount"
              :suggestions="[{ data: groups }]"
              :get-suggestion-value="getSuggestionValue"
              :limit="7"
              :input-props="{
                id: 'autosuggest__input',
                class: 'form-control',
                placeholder: 'Group Account',
              }"

              @input="searchGroups"
            >
              <template slot-scope="{suggestion}">
                <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
              </template>
            </vue-autosuggest>
          </b-form-group>

          <b-form-group
            v-if="myData.ignoreDateTime === false"
            label="From Date"
            label-for="from-date-lbl"
          >
            <flat-pickr
              id="from-date-lbl"
              v-model="myData.lte"
              class="form-control"
              static="true"
              :config="dpconfig"
            />
          </b-form-group>
          <b-form-group
            v-if="myData.ignoreDateTime === false"

            label="To Date"
            label-for="to-date-lbl"
          >
            <flat-pickr
              id="to-date-lbl"
              v-model="myData.gte"
              class="form-control"
              static="true"
              :config="dpconfig"
            />
          </b-form-group>

          <b-form-group
            label="Option 1"
            label-for="vi-ignoreDateTime"
          >
            <b-form-checkbox
              id="vi-ignoreDateTime"
              v-model="myData.ignoreDateTime"
              class="cursor-pointer custom-control-warning"
            >
              Ignore Date Time
            </b-form-checkbox>
          </b-form-group>
          <b-form-group
            v-if="$can('manage', 'all')"
            label="Option 2"
            label-for="vi-ignoreGroup"
          >
            <b-form-checkbox
              id="vi-ignoreGroup"
              v-model="myData.ignoreGroup"
              class="cursor-pointer custom-control-warning"
            >
              Ignore Group Account
            </b-form-checkbox>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <feather-icon
                class="cursor-pointer mr-25"
                icon="FileTextIcon"
              />
              <span
                v-if="myData.ignoreDateTime && myData.ignoreGroup"
              >
                Export All Data
              </span>
              <span

                v-else-if="myData.ignoreDateTime"
              >
                Export without Date Time
              </span>
              <span
                v-else-if="myData.ignoreGroup"
              >
                Export without Group Account
              </span>
              <span v-else>Export</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              <feather-icon
                class="cursor-pointer mr-25"
                icon="XCircleIcon"
              />
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { VueAutosuggest } from 'vue-autosuggest'
import moment from 'moment'
import { Indonesian } from 'flatpickr/dist/l10n/id.js'
import flatPickr from 'vue-flatpickr-component'
import countries from '@/@fake-db/data/other/countries'
import useJwt from '@/auth/jwt/useJwt'
import '@core/scss/vue/libs/vue-autosuggest.scss'

export default {
  components: {
    Indonesian,
    flatPickr,
    VueAutosuggest,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  // model: {
  //   prop: 'isExportSidebarActive',
  //   event: 'update:is-export-sidebar-active',
  // },
  props: {
    isExportSidebarActive: {
      type: Boolean,
      required: true,
    },
    apiGenereateExcel: {
      type: String,
      required: true,
    },
    backendUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      groups: [],
      required,
      alphaNum,
      email,
      countries,
      dpconfig: { // default
        wrap: true,
        altInput: true,
        enableTime: false,
        time_24hr: false,
        dateFormat: 'Y-m-d',
        locale: Indonesian,
      },
    }
  },
  methods: {
    moment,
    getSuggestionValue(val) {
      this.myData.ignoreGroup = false
      this.myData.groupAccount = val.item.name
      this.myData.fullNameGroup = val.item.description
      console.log('item', val.item)
    },
    searchGroups(text) {
      if (text) {
        clearTimeout(this.timeoutDebounce)
        this.timeoutDebounce = setTimeout(() => {
          useJwt.http.get(`group/search/${text}`)
            .then(response => {
              this.groups = response.data.groups
            })
        }, 300)
      }
    },
  },
  setup(props, { emit }) {
    const exportData = {
      groupAccount: '',
      fullNameGroup: '',
      lte: '',
      gte: '',
      ignoreGroup: false,
      ignoreDateTime: false,
    }

    const myData = ref(JSON.parse(JSON.stringify(exportData)))
    const resetmyData = () => {
      myData.value = JSON.parse(JSON.stringify(exportData))
    }

    const onSubmit = () => {
      console.log('onSubmit', myData.value)
      const payload = {
        ...myData.value,
      }
      useJwt.http.get(props.apiGenereateExcel, { params: payload }).then(response => {
        const uri = `${props.backendUrl}/${response.data.link_file_excel}`
        console.log(uri)
        window.open(uri)
      }).catch(err => {
        console.log('err', err)
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetmyData)

    return {
      myData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#export-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
